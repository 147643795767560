import { gql } from '@apollo/client';
import { WordPressBlocksViewer } from '@faustwp/blocks';
import { CoreColumns as CoreColumnsType } from 'generated-graphql';
import { get } from 'lodash';
import { generateStyle } from './helpers/styles';

function CoreColumns(props: CoreColumnsType) {
  const innerBlocks: any = props.innerBlocks || null;
  const { attributes } = props;

  const styleString = (get(attributes, 'style') || '{}') as any;
  const style = JSON.parse(styleString);
  const cssObj = generateStyle(style);
  const custommWidth = innerBlocks[0]?.attributes?.widthString;
  const isNotInContainer = attributes?.className?.includes('no-container');
  return (
    <div
      style={{
        ...cssObj,
        backgroundColor: attributes?.backgroundColor
          ? `var(--${attributes.backgroundColor})`
          : 'transparent',
      }}
      className={`core-columns ${attributes?.className}`}
    >
      <div
        className={`${isNotInContainer ? 'no-container-cols' : 'custom-width-cols'
          }  mw-lg-100 ${custommWidth && 'justify-content-lg-center'}`}
      >
        {custommWidth ? ( //@ts-ignore
          <WordPressBlocksViewer blocks={innerBlocks} />
        ) : (
          innerBlocks?.map((col: any, idx: number) => (
            <div
              className={`w-100 w-lg-50 d-flex`}
              style={{ minHeight: '100%' }}
              key={idx}
            >
              <WordPressBlocksViewer blocks={[col]} />
            </div>
          ))
        )}
      </div>
    </div>
  );
}

CoreColumns.fragments = {
  entry: gql`
    fragment CoreColumnsFragment on CoreColumns {
      attributes {
        style
        backgroundColor
        className
      }
    }
  `,
  key: `CoreColumnsFragment`,
};

CoreColumns.displayName = 'CoreColumns';

export default CoreColumns;
