import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styles from './Banner.module.scss';
import { Page_Builder_Sections_Banner } from 'generated-graphql';

import dynamic from 'next/dynamic';
import Head from 'next/head';
import BannerWork from './BannerWork';
import { useIsomorphicLayoutEffect } from '../ScrollPlayback/ScrollPlayback';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import BannerStandard from './BannerStandard';
import { useRouter } from 'next/router';
import Arrow from '/public/svgs/white-arrow.svg'

const ReactPlayer = dynamic(() => import("react-player"), { ssr: false });

const Banner: React.FC<Page_Builder_Sections_Banner> = (props) => {
  const {
    copy,
    type = 'full',
    backgroundColor = '#ffffff',
    background,
    image,
    video,
    videoUrl,
  } = props;

  const bannerRef = useRef(null);
  const videoUrlToShow = video?.mediaItemUrl || videoUrl;
  const [ready, setReady] = useState<boolean>(false);
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const router = useRouter();
  const isBrandsPage = router.asPath.includes('brands')


  useEffect(() => {
    if (videoUrlToShow) {
      setTimeout(() => {
        setShowVideo(true);
      }, 3500);
    }

  }, [videoUrlToShow]);

  useIsomorphicLayoutEffect(() => {

    let ctx: gsap.Context | null = null;
    let mm: gsap.MatchMedia | null = null;
    const bannerEl: HTMLElement | null = document.querySelector(`[class*="Banner_banner__"]`) || document.querySelector(`[class*="BannerStandard_banner__"]`) || document.querySelector(`[class*="BannerWork_banner__"]`);
    const arrowEl: HTMLElement | null = document.querySelector(`.${styles.brandsArrow}`);


    const isStandard = bannerEl?.classList.contains('banner-standard');

    const bannerCopy = document.querySelector(
      `[class*="Banner_banner__"]  [class*="Banner_copy__"]`,
    ) || document.querySelector(
      `[class*="BannerWork_banner__"]  [class*="BannerWork_copy__"] > div`,
    ) || document.querySelector(
      `[class*="Banner_banner__"]  [class*="Banner_textContent__"] > div`,
    ) || document.querySelector(
      `[class*="BannerStandard_banner__"]  [class*="BannerStandard_textContent__"]`,
    );


    const firstWrapper = document.querySelector(`.firstWrapper`);
    const heroEnd = document.querySelector('.heroEnd');
    const mainContent = document.querySelector(`.mainContent`) as HTMLElement;
    const body = document.querySelector(`body`);
    if (bannerEl && mainContent && firstWrapper && heroEnd) {
      // ScrollTrigger.refresh();

      if (firstWrapper && firstWrapper.parentElement) {
        const bannerElInner: HTMLElement | null = bannerEl.querySelector(`div`);
        firstWrapper.parentElement.style.background = bannerElInner && bannerElInner.style.backgroundColor ? bannerElInner.style.backgroundColor : '#00263e';
      }

      if (mainContent) {
        mainContent.style.background = "#ffffff";
      }

      ctx = gsap.context(() => {



        mm = gsap.matchMedia();

        const startTop = window.innerHeight + 100 - heroEnd.getBoundingClientRect().top;
        const diff = startTop > 0 ? startTop : 0;

        const t1 = gsap.timeline({
          scrollTrigger: {
            trigger: heroEnd,
            toggleActions: 'play none play play',
            pin: bannerEl,
            pinSpacing: false,
            // anticipatePin: 1,
            id: 'Banner',
            start: `top+=${diff}px bottom`,
            end: 'bottom+=400px bottom',
            scrub: diff > window.innerHeight / 2 ? 3 : 1,
            // markers: true,
          },


        });

        if (bannerCopy) {
          t1.to(bannerCopy, {
            opacity: 0,
            marginTop: '-30vh',
            duration: 1,
          });
        }

        const upMe = Math.round(window.innerHeight / 3);
        t1.to(
          firstWrapper,
          {

            duration: 1,
            y: `-${upMe}px`,
            marginBottom: `-${upMe + 1}px`,
            onComplete: () => {
              ScrollTrigger.refresh();
            }
          },
          '-=1'
        );

        mm.add("(min-width: 800px)", () => {
          if (!isStandard) {
            t1.fromTo(
              firstWrapper,
              {
                width: '80%'
              },
              {
                width: '100%',
              },
              '-=1'
            );
          }
        });





        // ScrollTrigger.addEventListener("refresh", () => {
        //   // t1.reverse();
        //   setShowVideo(true);
        // });

      });


    }

    return () => {
      if (ctx) {
        // ctx.kill();
        ctx.revert();
      }
      if (mm) {
        mm.revert();
      }
      ScrollTrigger.refresh();

      if (body) {
        body.style.backgroundColor = '#ffffff';
      }
    };

  }, [router.asPath]);



  useIsomorphicLayoutEffect(() => {
    let tl: any = null;
    const arrowEl: HTMLElement | null = document.querySelector(`.${styles.brandsArrow}`);

    if (arrowEl) {

      tl = gsap.timeline({ repeat: -1, id: "arrow", paused: true });

      tl.to(`.${styles.brandsArrow}`, {
        y: '-20',
        yoyo: true,
        duration: 0.5,
      });
      tl.to(`.${styles.brandsArrow}`, {
        y: '0',
        yoyo: true,
        duration: 0.5,
      });
      setTimeout(() => {
        tl.play()

      }, 3000)
    }
    return () => {
      if (tl) {
        tl.revert();
      }
    }


  }, [router.asPath])

  if (type === 'work') {
    return <BannerWork {...props} />
  }

  if (type === 'standard') {
    return <BannerStandard {...props} />
  }


  const copyElement = copy && (
    <div className="container">
      <div className={styles.content}>
        <div className="row">
          <div className={`col-12 col-lg-10 pe-lg-0`}>
            <div
              className={styles.copy}
              dangerouslySetInnerHTML={{ __html: copy || '' }}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <Head>
        <link rel="dns-prefetch" href="https://player.vimeo.com/" />
        {background?.sourceUrl && <link rel="prefetch" href={background?.sourceUrl} />
        }
        {videoUrlToShow && <link rel="preload" as="fetch" href={videoUrlToShow} />
        }
      </Head>

      <section className={styles.banner} ref={bannerRef}>
        <div
          className={`${styles.bannerCont}`}
          style={{
            backgroundColor: backgroundColor || '#000000',
            backgroundImage: background?.sourceUrl && !(ready && showVideo)
              ? `url("${background?.sourceUrl}")`
              : '',
          }}
        >
          <div className={`${styles.textContent}`} style={{ opacity: ready && showVideo ? '0' : '1' }}>
            {copyElement}
          </div>

          {isBrandsPage && <div className={styles.arrowWrapper}>
            <Arrow className={styles.brandsArrow} />
          </div>}

          {videoUrlToShow ? (

            <div style={{ opacity: (ready && showVideo) || !background?.sourceUrl ? '1' : '0' }}>
              <ReactPlayer
                className='player'
                url={videoUrlToShow}
                playing={true}
                width='100%'
                height='100%'
                muted
                controls={false}
                onReady={() => setReady(true)}
                loop
                playsinline={true}
              />
            </div>
          ) : null}
        </div>
      </section>
      <div className="heroEnd"></div>
    </div>
  );
};

export const bannerFields = `
  __typename
  background {
    sourceUrl
  }
  mobileBackground {
      sourceUrl
  }
  backgroundColor
  copy
  hubspotId
  type
  image {
    sourceUrl
  }
  video {
    mediaItemUrl
  }
  videoUrl
`;

export default Banner;