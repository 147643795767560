import styles from './ExpandableBoxes.module.scss';
import Plus from 'public/svgs/plus-icon.svg';
import Minus from 'public/svgs/minus-icon.svg';
import Link from 'next/link';
import {
  Page_Builder_Sections_ExpandableBoxes,
  Page_Builder_Sections_ExpandableBoxes_Boxes,
} from 'generated-graphql';
import { useEffect, useState } from 'react';
import { useIsomorphicLayoutEffect } from '../ScrollPlayback/ScrollPlayback';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import { gsap } from 'gsap';

gsap.registerPlugin(ScrollTrigger);

const ExpandableBoxes: React.FC<Page_Builder_Sections_ExpandableBoxes> = (
  props,
) => {
  const { boxes, text } = props;
  const [hoverIdx, setHoverIdx] = useState<number | null>(null);
  const [activateOverflowY, setActivateOverflowY] = useState<number | null>(null);
  useEffect(() => {

    let hoverIdxTimeoutId: null | ReturnType<typeof setTimeout> = null;

    const currentBox = document.querySelector(`.expandable-boxes-wrapper .box-${hoverIdx}`) as HTMLElement;
    let currentCopy: null | HTMLElement = null;
    let currentCopyHidden: null | HTMLElement = null;

    if (currentBox) {
      currentCopy = currentBox.querySelector(`div[class*="copy"]`) as HTMLElement;
      currentCopyHidden = currentBox.querySelector(`div[class*="copy"] p.hidden`) as HTMLElement;
    }


    if (hoverIdx !== null) {


      if (currentBox) {

        if (currentCopy && currentCopyHidden) {
          currentCopyHidden.style.maxHeight = `${currentBox.getBoundingClientRect().height - currentCopy.getBoundingClientRect().height}px`;
        }
        // currentBox.style.maxHeight = 
      }
      hoverIdxTimeoutId = setTimeout(() => setActivateOverflowY(hoverIdx), 600);
    }

    return () => {
      if (currentCopyHidden) {
        currentCopyHidden.style.maxHeight = "0";
      }

      if (hoverIdxTimeoutId) {
        clearTimeout(hoverIdxTimeoutId);
      }
    }
  }, [hoverIdx, setActivateOverflowY]);



  useIsomorphicLayoutEffect(() => {
    const showMobileBackground = () => {
      if (window.innerWidth < 600) {
        const allBoxes = document.querySelectorAll(".expandable-box")
        if (allBoxes.length) {
          allBoxes.forEach((box, idx) => ScrollTrigger.create({
            trigger: box,
            start: "top center",
            end: "+-300px",
            endTrigger: box,
            onToggle: (self) => setHoverIdx(self.isActive ? idx : null),
          }))
        }

      }
    }

    showMobileBackground();

    window.addEventListener("resize", showMobileBackground);

    return () => {
      window.removeEventListener("resize", showMobileBackground);
    }
  }, []);


  return (
    <section className='section expandable-boxes-wrapper'>
      <div className="container">
        {text && <div style={{ marginBottom: 100 }} dangerouslySetInnerHTML={{ __html: text }} />}
        <div className="row justify-content-between">
          {boxes?.map(
            (
              b: Page_Builder_Sections_ExpandableBoxes_Boxes | null,
              idx: number,
            ) => (
              <div
                key={idx + 'box'}
                className={`col-12 ${b?.smallBox ? 'col-xl-4' : 'col-xl-8'
                  } my-3 px-3 ${styles.specialWrapper} expandable-box`}
                onMouseEnter={() => setHoverIdx(idx)}
                onMouseLeave={() => setHoverIdx(null)}
              >
                <div

                  className={`${styles.box} ${activateOverflowY === idx ? styles.oYauto : ''} box-${idx}`}
                  style={{
                    backgroundImage: hoverIdx === idx
                      ? `url(${b?.expandedBackgroundImage?.mediaItemUrl})`
                      : `url(${b?.backgroundImage?.mediaItemUrl})`
                    ,
                    backgroundSize: 'cover',
                    backgroundColor: b?.expandedIconColor || "",
                    borderRadius: "18px"

                  }}

                >
                  <div className={`${styles.copy} `} >

                    <div
                      dangerouslySetInnerHTML={{ __html: b?.shortText || '' }}
                    />
                  </div>
                </div>
              </div>
            )

          )}
        </div>
      </div>
    </section >
  );
};

export default ExpandableBoxes;
